import React from 'react'; 



class PhoneContent extends React.Component{
    render() {
        const margin = {
            marginTop : '10%'
        }
        const articlePad = {
            padding:'5%'
        }
        const img = {
            width : '200px',
            height :'200px',
        }
        const a ={
            textDecoration:'none',
            fontWeight: 'bold' // 添加fontWeight属性
        }
        return(
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-3'>

                    </div>
                    <div className='col-7 '>
                    <header>
                        <h1 style={a}>亦宬水電工程行</h1>
                    </header>                      
                    </div>
                    <div className='col-2'>

                    </div>
                </div>
                <div className='row' style={margin}>
                    <div className='contentFontNormal' style={articlePad}>
                        板橋水電問題、新莊水電、三重水電<br/>
                        <br/>
                        專營北台灣水電工程，各式疑難雜症皆可處理<br/>                       
                        只要是雙北市，皆在服務範圍
                    </div>
                </div>
                <div className='row' style={margin}>
                    <div className='contentFontNormal' style={articlePad}>
                        亦宬水電、板橋水電、新莊水電、三重水電，雙北水電<br/><br/>
                        在業界有口皆碑，不怕您打聽<br/>
                        <br/>
                        
                        <br/>
                        板橋在地服務多年，專業的水電技術團隊<br/>
                        不僅提供水電規劃、室內裝潢也首屈一指
                    </div>
                </div>
                <div className='row' style={margin}>
                    <div className='contentFontNormal' style={articlePad}>
                        亦宬水電提供免費線上估價服務，並有售後保固，不再擔心受害被騙
                    </div>
                </div>
                <div className='row' style={margin}>
                    <div className='col-3'>

                    </div>
                    <div className='col-6' style={img}>
                        <img src='images/MAMA2.jpg'  alt='...'/>
                    </div>              
                </div>
                <div className='row' style={margin}>
                    <div className='col-3'>

                    </div>
                    <div className='col-8' style={img}>
                        <div style={articlePad}>
                            歡迎加LINE<br/>
                            亦宬水電<br/>
                            會有專人為您服務
                        </div>
                    </div>              
                </div>

            </div>
        )
    }
}

export default PhoneContent;