import React from 'react'; 
import '../../css/Footer.css'

class PhoneFooter extends React.Component{
    render() {

        return(
            <div className='container-fluid footerPhone'>
                <div className='row'>
                    <div className='col-1'>

                    </div>
                    <div className='col-3 footertext'>
                        地址
                    </div>
                    <div className='col-8 footertext'>
                    新北市板橋區南雅南路二段11之12號13樓
                    </div>
                </div>
                <div className='row'>
                    <div className='col-1'>
                        
                    </div>
                    <div className='col-3 footertext'>
                        電話    
                    </div>
                    <div className='col-8 footertext'>
                        0981371886
                    </div>
                </div>
                <div className='row'>
                    <div className='col-1 footertext'>

                    </div>
                    <div className='col-3 footertext'>
                        營業時間
                    </div>
                    <div className='col-8 footertext'>
                        24HR 全年無休
                    </div>
                </div>
                <div className='row'>
                    <div className='col-1'>

                    </div>
                    <div className='col-3 footertext'>
                    亦宬水電
                    </div>
                    <div className='col-8 footertext'>
                    亦宬水電 板橋水電行第一把交椅
                    </div>
                </div>
            </div>           
        )
    }
}

export default PhoneFooter;