import React from 'react'; 
import { Link } from 'react-router-dom';

/*                            <Link className="navbar-item contentFontCute" style={a} to='/' >欣揚工程有限公司</Link>
                            <Link className="navbar-item contentFontCute" style={a} to='/about'>關於欣揚</Link>
                            <Link className="navbar-item contentFontCute" style={a} to='/contact'>連絡我們</Link> */

class PhoneHeader extends React.Component{
    render() {
        const a ={
            textDecoration:'none',
            fontWeight: 'bold' // 添加fontWeight属性
        }
        return(
            <nav className="navbar navbar-expand-lg navbar-light bg-light">
                <div className="container-fluid">
                <Link className="navbar-item" style={a} to='/' >亦宬水電工程有限公司</Link>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarNavDropdown">
                        <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                            <li className="nav-item contentFontCutePhone2">
                            板橋水電 三重水電 新莊水電
                            </li>
                            <li className="nav-item contentFontCutePhone2">
                            專治雙北地區各式水電問題
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        )
    }
}

export default PhoneHeader;