import React from 'react'; //可以定義組件 默認從modules找 
//import ReactDom from 'react-dom'; //負責渲染組件 ReactDOM.render(<p></p>,document.getElemenById('root'));
//若不使用jsx babel 必須使用React.creatElement('p',{className:Login}) 多層嵌套會很麻煩
import { createRoot } from 'react-dom/client';
//render return藥用()包起來  最外層可以包<React.Fragment></React.Fragment>
//npm install bulma npm install node-sass
import '../node_modules/bulma/css/bulma.min.css'
//廣告輪播套件引入 react-slick   slick-carousel  使用npm install

import Web from './components/Web';
import About from './components/about/About';
import Contact from './components/contact/Contact';



import '../node_modules/slick-carousel/slick/slick.css';
import '../node_modules/slick-carousel/slick/slick-theme.css';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css'
import '../node_modules/bootstrap/dist/js/bootstrap.min.js'
//路由管理
import  {BrowserRouter,Routes, Route} from "react-router-dom";





const root = document.getElementById('root');
createRoot(root).render(
<React.StrictMode>
    <BrowserRouter>
        <Routes>
            <Route exact path='/'  element={<Web/>}/>
            <Route path='/about' element={<About/>}/>
            <Route path='/contact' element={<Contact/>}/>
        </Routes>
    </BrowserRouter>
</React.StrictMode>
);
