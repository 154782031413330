import React from 'react';
import Header from '../Header';
import Introduce from './Introduce'
import Footer from '../Footer'

class About extends React.Component{
    render() {  
        return(
            <React.Fragment>
                <Header/>
                <Introduce/>
                <Footer/>
            </React.Fragment>         
        );
    };
}

export default About;
