import React from 'react';

class ContactMethod extends React.Component{



    render() {

        const width= {
            width: '18rem'
        }

        const colDisplay = {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '10%'
        };
        return(
            <React.Fragment>
                <div className='container-fluid'></div>
                    <div className='row'>
                        <div className='col-4'  style={colDisplay}>
                            <div className='card' style={width}>
                            </div>
                        </div>
                        <div className='col-4' style={colDisplay}>
                            <div className='card' style={width}>
                                <img src='images/MAMA.jpg' className='card-img-top' alt='...'/>
                                <div className='card-body'>
                                    <p className='card-text'>歡迎透過LINE連絡亦宬 讓我們為您免費線上估價</p>
                                </div>
                            </div>
                        </div>
                        <div className='col-4' style={colDisplay}>          
                            <div className='card' style={width}>
                            </div>
                        </div>
                    </div>              
            </React.Fragment>
        );
    };
}

export default ContactMethod;