import React from 'react';
import '../../css/animate.css/animate.css'; // 
import '../../css/Font.css'


class Introduce extends React.Component{

    render() { 
        const rowHigh = {
            marginTop:'5%'
        };
        const colDisplay = {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        };
        const h6Padding = {
            paddingLeft: '10%',
            paddingRight: '10%'
        };
        return(
            <div className='container-fluid'>
                <div className='row' style={rowHigh}>
                    <div className='col-3'></div>
                    <div className='col-6' style={colDisplay}><h1 className='animate__animated animate__fadeInDown contentFontBigTitle'>關於亦宬</h1></div>
                    <div className='col-3'></div>
                </div>
                <div className='row' style={rowHigh}>
                    <div className='col-4' style={colDisplay}><h6 className='animate__animated animate__slideInLeft contentFontMiddle'>服務項目</h6></div>
                    <div className='col-4' style={colDisplay}><h6 className='animate__animated animate__slideInUp contentFontMiddle'>公司簡介</h6></div>
                    <div className='col-4' style={colDisplay}><h6 className='animate__animated animate__slideInUp contentFontMiddle'>服務地區</h6></div>
                </div>
                <div className='row' style={rowHigh}>
                    <div className='col-4' style={colDisplay}>                        
                            <h6 className='contentFontSmall fontSpacing' style={h6Padding}>
                            水電服務項目完整，為您把關居家安全
                            台北/新北市水電工程 提供完善家庭/公司/店面水電需求，不管是衛浴設備更換、全戶電線更換、
                            水電消防空調、抓漏、防水、裝潢、泥作磁磚、油漆、系統櫃、鐵工、鋁窗以及外牆拉皮
                            水電管線重拉等等的水管配管工程或是電線配置工程皆有服務，從規劃設計到維護保固水電大小事都能ㄧ包到底。
                            </h6>
                        </div>
                    <div className='col-4' style={colDisplay}>
                        <h6 className='contentFontSmall fontSpacing' style={h6Padding}>
                            亦宬水電工程行在地深耕多年，雙北市水電領域翹楚，市民誠心推薦之優良工程行，
                            具營業執照、經驗豐富能快速解決問題，有一套專業的水電SOP流程，從規劃-設計-裝配到保固維護管理，目的增加施工效率，以最好的施工品質與技術來完成客戶之需求。   
                            亦宬台北水電工程提供全戶電線更換、水電管線重拉、衛浴設備安裝等居家水電服務，如您家中有這類需求或水電健檢服務
                            ，歡迎與我們聯絡，本公司會盡快給您回覆，並依客戶需求作水電工程。
                        </h6>
                    </div>
                    <div className='col-4' style={colDisplay}>
                        <h6 className='contentFontSmall fontSpacing' style={h6Padding}>
                            ●台北市：中正區/內湖區/大同區/中山區/南港區/文山區/松山區/大安區/萬華區/信義區/士林區/北投區。<br/>
                            ●新北市：新莊區/新店區/蘆洲區/汐止區/淡水區/三峽區/林口區/五股區/八里區/深坑區/坪林區/烏來區/三重區/樹林區/泰山區/板橋區/中和區/土城區/永和區/鶯歌區/瑞芳區/平溪區等
                            亦宬水電工程守護您家中安全，售前/售後服務都能感受無比用心!
                        </h6>
                    </div>
                </div>


            </div>
            
        );
    };
}

export default Introduce;


