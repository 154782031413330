import React from 'react'; 
import '../css/Footer.css'

class Footer extends React.Component{
    render() {

        return(
            <div className='container-fluid footerPhone'>
                <div className='row'>
                    <div className='col-3'>

                    </div>
                    <div className='col-3'>
                        地址
                    </div>
                    <div className='col-3'>
                    新北市板橋區南雅南路二段11之12號13樓
                    </div>
                    <div className='col-3'>

                    </div>
                </div>
                <div className='row'>
                    <div className='col-3'>
                        
                    </div>
                    <div className='col-3'>
                        電話    
                    </div>
                    <div className='col-3'>
                        0981371886
                    </div>
                    <div className='col-3'>

                    </div>
                </div>
                <div className='row'>
                    <div className='col-3'>

                    </div>
                    <div className='col-3'>
                        營業時間
                    </div>
                    <div className='col-3'>
                        24HR 全年無休
                    </div>
                    <div className='col-3'>

                    </div>
                </div>
                <div className='row'>
                    <div className='col-3'>

                    </div>
                    <div className='col-3'>
                        亦宬水電工程有限公司
                    </div>
                    <div className='col-3'>
                        亦宬水電 板橋水電第一把交椅
                    </div>
                    <div className='col-3'>

                    </div>
                </div>
            </div>           
        )
    }
}

export default Footer;