import React from 'react'; 
import PhoneHeader from './phone/phoneHeader';
import SliderSingle from './SliderSingle';
import PhoneContent from './phone/phoneContent';
import PhoneFooter from './phone/phoneFooter';


class PhoneWeb extends React.Component{
    render() {
        return(
            <div>
                <PhoneHeader/>
                <SliderSingle/>
                <PhoneContent/>
                <PhoneFooter/>
            </div>

        )
    }
}

export default PhoneWeb;