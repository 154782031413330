import React from 'react'; 
import SliderMult from './SliderMult';
import SliderSingle from './SliderSingle';
import '../css/Font.css'


class Content extends React.Component{
    render() {
        const style = {
            marginTop:'5%', 
            height:'20%'        
        };
        const padding ={
            paddingRight:'10%'
        }
        return(
            <div className='container-fluid'>
                <div className="row">
                    <div className='col-md-12'>
                        <SliderSingle/>
                    </div>                    
                </div>
                <div className="row">
                    <div className='col-md-4'>                                          
                    </div> 
                    <div className='col-md-4'> 
                        <img src='images/成果10.jpg' alt=''></img>                       
                    </div> 
                    <div className='col-md-4'>                       
                    </div> 
                </div>
                <div className="row" style={style}>
                    <div className='col-md-2'>                        
                    </div> 
                    <div className='col-md-4'>
                        <h1 className='contentFontNormal'>擔心找不到好的水電行嗎? </h1>
                        <h1 className='contentFontNormal'>還在擔心沒有好的裝潢團隊嗎?</h1>
                    </div> 
                    <div className='col-md-1'>                       
                    </div> 
                    <div className='col-md-4'>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <h4 className='contentFontSmall'>亦宬擁有最完整的施工團隊</h4>
                        <h4 className='contentFontSmall'>亦宬擁有最熱情的服務品質</h4>
                        <h4 className='contentFontSmall'>亦宬擁有最專業的技術證照</h4>
                        <h4 className='contentFontSmall'>亦宬擁有最合理的施作報價</h4>
                    </div>                    
                </div>
                <div className="row" style={style}>
                    <div className='col-md-3'>                        
                    </div> 
                    <div className='col-md-4'>
                        <h1 className='contentFontNormal'>不用怕爛尾 不用怕被射後不理 </h1>
                    </div> 
                    <div className='col-md-1'>                       
                    </div> 
                    <div className='col-md-4'>
                        <br/>
                        <br/>
                        <br/>
                        <h4 className='contentFontSmall' style={padding}>具營業執照與國內相關規定之水電工程證照<br/>沒有噗龍共的師傅 各各年資豐富 身經百戰</h4>
                        <br/>
                        <h4 className='contentFontSmall' style={padding}>施工前與客戶溝通確認需求並做水電健檢，視居家或店面電容量需求量身規劃設計，極具效率解決水電問題</h4>
                        <br/>
                        <h4 className='contentFontSmall' style={padding}>注重完工後美觀整齊度，售後保固維修有口碑，保障您的居家安全是亦宬的宗旨</h4>
                    </div>         
                </div>
                <div className="row" style={style}>
                    <div className='col-md-2'>                        
                    </div> 
                    <div className='col-md-4'>
                        <h1 className='contentFontNormal'>男人給不了你承諾</h1>
                        <h1 className='contentFontNormal'>亦宬水電工程行能給你</h1>
                    </div> 
                    <div className='col-md-1'>                       
                    </div> 
                    <div className='col-md-4'>
                        <br/>
                        <br/>
                        <br/>
                        <h4 className='contentFontNormal'>不論案子大小 亦宬都會用最高規格服務</h4>
                        <h4 className='contentFontNormal'>上至大型新建案</h4>
                        <h4 className='contentFontNormal'>小至公寓廁所整修</h4>
                        <h4 className='contentFontNormal'>亦宬水電工程行都會給予售後保固</h4>
                        <br/>
                        <br/>
                    </div>                    
                </div>
                <div className="row" style={style}>
                    <div className='col-md-12'>
                        <SliderMult/>
                    </div> 
                </div>                                       
            </div>                       
        )
    }
}

export default Content;