import React from 'react'; 
import Header from './Header';
import Content from './Content';
import Footer from './Footer';
import PhoneWeb from './phoneWeb';


/*               */
class Web extends React.Component{
    

    constructor(props) {
        super(props);
        this.state = {
          device: 'desktop',
        };
    }
    componentDidMount() {
        this.setScreenSize();
        window.addEventListener('resize', this.setScreenSize);
      }
    
      componentWillUnmount() {
        window.removeEventListener('resize', this.setScreenSize);
      }
    
      setScreenSize = () => {
        const isMobile = window.matchMedia('(max-width: 576px)').matches;
        const isTablet = window.matchMedia('(min-width: 577px) and (max-width: 992px)').matches;
        
        let device;
        if (isMobile) {
          device = 'mobile';
        } else if (isTablet) {
          device = 'tablet';
        } else {
          device = 'desktop';
        }
        this.setState({ device });
      };

      render() {
        const { device } = this.state;
    
        switch (device) {
          case 'mobile':
            return <PhoneWeb/>;
          case 'tablet':
            return  <PhoneWeb/>;
          default:
            return (<div>
            <Header/>
            <Content/>
            <Footer/>             
        </div> )
        }
      }
}

export default Web;