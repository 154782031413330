import React from 'react'; 
import Slider from 'react-slick';

class ImageCard extends React.Component{
    render() {
        const margin = {
            marginLeft:'5%',
            marginRight:'5%'// 設定 margin 
        };
        return( 
        <div className='card'  style={margin}>
            <div className='card-image'>
                <figure className='image is-4by3'>
                    <img src={'images/'+this.props.src} alt='板橋水電行' />
                </figure>
            </div>
            <div className='card-content'>
                <div className='media'>
                    <div className='media-content'>
                        <p className='title is-4'>{this.props.title}</p>
                        <p className='subtitle is-6'>{this.props.loco}</p>
                    </div>
                </div>
                <div className='content'>                 
                    <br />
                    <p className='subtitle is-6'>{this.props.desciption}</p>
                </div>
            </div>
        </div>)       
    }
}

class SliderMult extends React.Component{
    constructor(props) {
        super(props);
        this.play = this.play.bind(this);
        this.pause = this.pause.bind(this);
      }
      play() {
        this.slider.slickPlay();
      }
      pause() {
        this.slider.slickPause();
      }

    render() {
        const settings = {
            dots: false,
            infinite: true,
            slidesToShow: 4,
            slidesToScroll:1,
            autoplay: true,
            speed: 2000,
            autoplaySpeed: 3000,
            cssEase: 'linear'
          };
        return (
        <div  style={{marginLeft:'5%',marginRight:'5%'}}>
            <Slider {...settings}>
            <ImageCard src={'施工前1.jpg'} title='浴廁翻修' loco='新北市板橋' desciption='優化設計 年老翻修'/>
            <ImageCard src={'施工後1.jpg'} title='浴廁翻修' loco='新北市板橋' desciption='施工後 上廁所都成了一種享受'/>
            <ImageCard src={'施工前2.jpg'} title='新建大樓' loco='新北市五股' desciption='水錶機房配置'/>
            <ImageCard src={'施工後2.jpg'} title='新建大樓' loco='新北市五股' desciption='整齊劃一 看了就舒服'/>
            <ImageCard src={'施工前3.jpg'} title='室內裝修' loco='新北市三重' desciption='重新拉皮 壁癌整治'/>
            <ImageCard src={'施工後3.jpg'} title='室內裝修' loco='新北市三重' desciption='施工後  再也不想去外面花錢住飯店了'/>
            <ImageCard src={'施工前4.jpg'} title='室內裝修' loco='新北市土城' desciption='打掉重練 老屋新生'/>
            <ImageCard src={'施工後4.jpg'} title='室內裝修' loco='新北市土城' desciption='賦予老屋蛻變的新生命'/>
            </Slider>
        </div>
        );
    }
}

export default SliderMult;