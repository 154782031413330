import React from 'react'; 
import { Link } from 'react-router-dom';

class Header extends React.Component{


    render() {

        const a ={
            textDecoration: 'none',
      fontWeight: 'bold', // 设置字体粗细为粗体
      fontSize: '30px' // 设置字体大小为18像素
        }
        return(
            <div className='main'>
                <nav className="navbar is-light" role="navigation" aria-label="main navigation">
                    <div id="navbarBasicExample" className="navbar-menu">
                        <div className="navbar-start">
                            <Link className="navbar-item " style={a} to='/' >板橋水電行</Link>
                            <Link className="navbar-item " style={a} to='/about'>關於亦宬水電</Link>
                            <Link className="navbar-item" style={a} to='/contact'>連絡我們</Link>
                        </div>
                    </div>
                </nav>   
            </div>           
        )
    }
}

export default Header;

