import React from 'react';
import Header from '../Header';
import Method from './ContactMethod';
import Footer from '../Footer';

class Contact extends React.Component{

    render() {
        return(
            <React.Fragment>
                <Header/>
                <Method/>
                <Footer/>
            </React.Fragment>
        );
    };
}

export default Contact;