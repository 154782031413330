import React from 'react'; 
import Slider from 'react-slick';

class SliderSingle extends React.Component{
    constructor(props) {
        super(props);
        this.play = this.play.bind(this);
        this.pause = this.pause.bind(this);
      }
      play() {
        this.slider.slickPlay();
      }
      pause() {
        this.slider.slickPause();
      }

    render() {
        const settings = {
            dots: false,
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            fade: true,
            autoplay: true,
            speed: 2000,
            autoplaySpeed: 2000,
            cssEase: "linear"
          };
        const margin = {
            marginLeft:'0%',
            marginRight:'0%'// 設定 margin 
        };
        return (
        <div>
            <Slider {...settings}>
            <div>
                <figure className="image is-4by3" style={margin}>
                    <img src='images/成果1.gif' alt='板橋水電' />
                </figure>   
            </div>
            <div>
                <figure className="image is-4by3" style={margin}>
                    <img src='images/成果2.jpg' alt='板橋水電' />
                </figure>   
            </div>
            <div>
                <figure className="image is-4by3" style={margin}>
                    <img src='images/成果3.jpg' alt='板橋水電' />
                </figure>   
            </div>
            <div>
                <figure className="image is-4by3" style={margin}>
                    <img src='images/成果4.jpg' alt='板橋水電' />
                </figure>   
            </div>
            <div>
                <figure className="image is-4by3" style={margin}>
                    <img src='images/成果5.jpg' alt='板橋水電' />
                </figure>   
            </div>
            <div>
                <figure className="image is-4by3" style={margin}>
                    <img src='images/成果6.jpg' alt='三重水電' />
                </figure>   
            </div>
            <div>
                <figure className="image is-4by3" style={margin}>
                    <img src='images/成果7.jpg' alt='新莊水電' />
                </figure>   
            </div>
            <div>
                <figure className="image is-4by3" style={margin}>
                    <img src='images/成果8.jpg' alt='雙北水電' />
                </figure>   
            </div>
            <div>
                <figure className="image is-4by3" style={margin}>
                    <img src='images/成果9.jpg' alt='新北水電' />
                </figure>   
            </div>       
            </Slider>
        </div>
        );
    }
}

export default SliderSingle;